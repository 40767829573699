.shadow_0_0_20 {
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
}
.dropshadow_0_0_20 {
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.161));
}
.shadow_0_0_10 {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.161);
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_Rg.woff2') format('woff2'), url('/assets/fonts/Foco_W_Rg.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_It.woff2') format('woff2'), url('/assets/fonts/Foco_W_It.woff') format('woff');
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_Lt.woff2') format('woff2'), url('/assets/fonts/Foco_W_Lt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_LtIt.woff2') format('woff2'), url('/assets/fonts/Foco_W_LtIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_Bd.woff2') format('woff2'), url('/assets/fonts/Foco_W_Bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "foco";
  src: url('/assets/fonts/Foco_W_BdIt.woff2') format('woff2'), url('/assets/fonts/Foco_W_BdIt.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
.fontRegular {
  font-family: "foco";
  font-weight: 400;
}
.fontRegularItalic {
  font-family: "foco";
  font-weight: 400;
  font-style: italic;
}
.fontMedium {
  font-family: "foco";
  font-weight: 500;
}
.fontMediumItalic {
  font-family: "foco";
  font-weight: 500;
  font-style: italic;
}
.fontBold {
  font-family: "foco";
  font-weight: 700;
}
.fontBoldItalic {
  font-family: "foco";
  font-weight: 700;
  font-style: italic;
}
.fontLight {
  font-family: "foco";
  font-weight: 300;
}
.fontLightItalic {
  font-family: "foco";
  font-weight: 300;
  font-style: italic;
}
.fontSize16 {
  font-size: 1rem;
}
.fontSize18 {
  font-size: 1.125rem;
}
.fontSize19 {
  font-size: 1.1704rem;
}
.fontSize20 {
  font-size: 1.25rem;
}
.fontSize22 {
  font-size: 1.375rem;
}
.fontSize28 {
  font-size: 1.75rem;
}
.fontSize34 {
  font-size: 2.125rem;
}
.fontRegular24 {
  font-family: "foco";
  font-weight: 400;
  font-size: 1.5rem;
}
.fontRegular20 {
  font-family: "foco";
  font-weight: 400;
  font-size: 1.25rem;
}
.fontRegular18 {
  font-family: "foco";
  font-weight: 400;
  font-size: 1.125rem;
}
.fontRegular16 {
  font-family: "foco";
  font-weight: 400;
  font-size: 1rem;
}
.fontBold16 {
  font-family: "foco";
  font-weight: 700;
  font-size: 1rem;
}
.fontLight16 {
  font-family: "foco";
  font-weight: 300;
  font-size: 1rem;
}
.fontLight18 {
  font-family: "foco";
  font-weight: 300;
  font-size: 1.25rem;
}
.fontLight22 {
  font-family: "foco";
  font-weight: 300;
  font-size: 1.375rem;
}
#main-nav {
  font-family: "foco";
  font-weight: 300;
}
#main-nav ul > li {
  width: auto;
  margin: auto;
}
#main-nav ul > li:first-child {
  margin-left: 0;
}
#main-nav ul > li > a {
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: 1rem;
  color: #1A171B;
}
#main-nav ul > li > a:hover {
  text-decoration: none;
}
#main-nav .dropdown-menu.megamenu a {
  font-size: 1.375rem;
}
#main-nav .dropdown-menu.megamenu .mega-dropdown-menu {
  padding-inline-start: 24px;
  padding-bottom: 16px;
  list-style-image: url(/assets/image/icons/pfeil-rechts-schwarz-10.png);
}
#main-nav .dropdown-menu.megamenu .mega-dropdown-menu a {
  text-transform: none !important;
  font-family: foco;
  font-weight: 300;
  font-size: 1.175rem !important;
  padding-bottom: 2px;
  letter-spacing: unset !important;
}
#main-nav .dropdown-menu a {
  font-size: 0.875rem;
}
#serviceNavi-top ul {
  padding-left: 0;
  transition: 0.2s;
}
#serviceNavi-top ul li {
  display: inline;
  margin-left: 5px;
}
@media all and (min-width: 992px) {
  .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar .has-megamenu {
    position: static!important;
  }
  .navbar .megamenu {
    left: 0;
    right: 0;
    top: 40px;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
    -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.161);
  }
  .navbar .megamenu .mega-dropdown-menu {
    list-style-type: none;
    padding-inline-start: 16px;
  }
  .navbar .megamenu .mega-dropdown-menu a {
    text-transform: none !important;
    font-family: foco;
    font-weight: 300;
    font-size: 1.275rem !important;
    padding-bottom: 2px;
    letter-spacing: unset !important;
  }
  .navbar .megamenu .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 20px;
  }
  .navbar .megamenu .grid-container > div {
    font-family: "foco";
    font-weight: 300;
    font-size: 1.375rem;
    color: #707070;
    border-bottom-style: dotted;
    width: 100%;
    display: inline-block;
    border-width: 2px;
  }
  .navbar .megamenu .grid-container > div:hover {
    font-family: "foco";
    font-weight: 300;
  }
  .navbar .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.breadcrumb-container {
  box-shadow: inset 0px 5px 5px 0px rgba(0, 0, 0, 0.161), 0px 0px 20px 0px rgba(0, 0, 0, 0.161);
  line-height: 40px;
}
.breadcrumb-container .breadcrumb {
  display: table;
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
}
.breadcrumb-container .breadcrumb .breadcrumbs {
  display: table-cell;
  padding: 0;
  list-style-type: none;
}
.breadcrumb-container .breadcrumb .breadcrumbs li {
  display: inline-block;
}
.breadcrumb-container .breadcrumb .breadcrumbs li a,
.breadcrumb-container .breadcrumb .breadcrumbs li span {
  color: #707070;
}
.breadcrumb-container .breadcrumb .breadcrumbs li a::before,
.breadcrumb-container .breadcrumb .breadcrumbs li span::before {
  content: "»  ";
  padding: 0 6px;
}
@media only screen and (max-width: 991px) {
  /* The side navigation menu */
  #main-nav {
    height: 100%;
    /* 100% Full-height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    left: 0;
    background-color: #ffffff;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.25s;
    /* 0.5 second transition effect to slide in the sidenav */
    z-index: 10000;
    align-items: flex-start;
    /* The navigation menu links */
    /* Position and style the close button (top right corner) */
  }
  #main-nav ul > li {
    margin-right: 20px;
  }
  #main-nav ul > li > a {
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-size: 18px;
    color: #1A171B;
  }
  #main-nav ul > li > a:hover {
    color: #E2001A;
    text-decoration: none;
  }
  #main-nav ul > li.active > a.active {
    color: #E2001A;
  }
  #main-nav .navbar-collapse {
    height: inherit;
  }
  #main-nav .navbar-sidebar-header {
    display: block !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.161);
  }
  #main-nav .nav {
    display: block;
  }
  #main-nav .nav .dropdown-menu {
    position: relative;
    border: 0;
    width: 100%;
    margin-left: 1rem;
  }
  #main-nav .nav .dropdown-menu a {
    font-size: 1.375rem;
  }
  #main-nav .megamenu .sectionLink {
    font-family: "foco";
    font-weight: 300;
    font-size: 18px;
    color: #707070;
    border-bottom-style: dotted;
    border-bottom-width: 2px;
    width: 100%;
    display: inline-block;
  }
  #main-nav a {
    padding: 0.25rem 8px 0.25rem 0;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  #main-nav a:hover {
    color: #f1f1f1;
  }
  #main-nav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  #main-nav .btn-link {
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-size: 18px;
    color: #707070;
    border-bottom-style: dotted;
    border-bottom-width: 2px;
  }
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
}
@media only screen and (max-width: 991px) and screen and (max-height: 450px) {
  .navbar {
    padding-top: 15px;
  }
  .navbar a {
    font-size: 18px;
  }
}
.back-to-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: none;
  background-color: rgba(0, 0, 0, 0.07);
  color: #FFFFFF;
}
.back-to-top:hover {
  color: #000000;
}
